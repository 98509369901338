<template>
  <div class="test-case-detail">
    <div class="back-button-container">
      <a
        @click="clearTestGroup()"
        class="backButton"
        id="bac-btn"
        plain
        :class="{ active: false }"
      >
        <!-- <img
						class="flag"
						:src="require(`@/assets/images/flags/${language.code}.png`)"
					/> -->
        <p class="font-medium block notification-title">
          <feather-icon
            icon="ChevronLeftIcon"
            class="inline-block mr-2 back-button-icon"
            svgClasses="w-4 h-7"
          />
          <span class="back-button-span">
            {{
              lang.botMaker.testGroups.view.backToTestCase[languageSelected]
            }}</span
          >
        </p>
      </a>
    </div>
    <div class="vx-row test-case-header" id="test-case-header">
      <vs-col vs-w="10">
        <vs-row vs-w="8" class="flex title">
          <h2>
            {{
              lang.botMaker.testGroups.table.groups.testSuite[languageSelected]
            }}
          </h2>
        </vs-row>
        <vs-row vs-w="8" class="flex title">
          <h1>
            {{ selectedTestGroup.name }}
          </h1>
        </vs-row>
      </vs-col>
      <vs-col vs-w="2" class="more-button-container">
        <div
          class="moreCaseButton more-button-icon more-button-icon-group"
          v-if="
            isAuthAux('konecta.test_groups.edit') ||
            isAuthAux('konecta.test_groups.execute')
          "
        >
          <feather-icon
            icon="MoreVerticalIcon"
            id="more-options-btn"
            class="inline-block mr-8 more-button-icon"
            @click="moreGroupOptions()"
            svgClasses="w-8 h-8"
          >
          </feather-icon>
          <KonectaDropdown
            :click-outside="outsideCase"
            v-if="groupOptionsPopup"
          >
            <div>
              <ul>
                <li
                  @click="openTestGroupEditor(selectedTestGroup)"
                  id="edit-btn"
                  v-if="isAuthAux('konecta.test_groups.edit')"
                >
                  <vs-row class="option-row">
                    <feather-icon
                      icon="EditIcon"
                      class="inline-block mr-2"
                      svgClasses="w-4 h-4"
                    />
                    <p>
                      {{ lang.botMaker.testGroups.view.edit[languageSelected] }}
                    </p>
                  </vs-row>
                </li>
                <li
                  @click="excecuteTestGroup(selectedTestGroup._id)"
                  id="execute-btn"
                  v-if="isAuthAux('konecta.test_groups.execute')"
                >
                  <vs-row class="option-row">
                    <feather-icon
                      icon="PlayCircleIcon"
                      class="inline-block mr-2"
                      svgClasses="w-4 h-4"
                    />
                    <p>
                      {{
                        lang.botMaker.testGroups.view.execute[languageSelected]
                      }}
                    </p>
                  </vs-row>
                </li>
              </ul>
            </div>
          </KonectaDropdown>
        </div>
      </vs-col>
    </div>
    <div class="vx-row test-group-table" id="test-group-table">
      <vs-row class="table-header">
        <vs-col vs-w="10">
          <vs-row vs-w="8" class="flex title">
            <h2>
              {{
                lang.botMaker.testGroups.table.cases.testCase[languageSelected]
              }}
            </h2>
          </vs-row>
          <vs-row vs-w="8" class="flex title">
            <h1>
              {{ selectedTestCase.name }}
            </h1>
          </vs-row>
        </vs-col>
        <vs-col vs-w="2" class="more-button-container">
          <div
            class="moreCaseButton more-button-icon more-button-icon-case"
            v-if="isAuthAux('konecta.test_groups.edit')"
          >
            <feather-icon
              icon="MoreVerticalIcon"
              class="inline-block mr-8 more-button-icon"
              @click="moreCaseOptions()"
              id="more-case-options-btn"
              svgClasses="w-8 h-8"
            >
            </feather-icon>
            <KonectaDropdown
              :click-outside="outsideCase"
              v-if="caseOptionsPopup"
            >
              <div>
                <ul>
                  <li
                    @click="openAddTestCase(selectedTestCase)"
                    id="edit-case-btn"
                  >
                    <vs-row class="option-row">
                      <feather-icon
                        icon="EditIcon"
                        class="inline-block mr-2"
                        svgClasses="w-4 h-4"
                      />
                      <p>
                        {{
                          lang.botMaker.testGroups.view.edit[languageSelected]
                        }}
                      </p>
                    </vs-row>
                  </li>
                </ul>
              </div>
            </KonectaDropdown>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="12">
          <div
            class="pt-4 pb-base vs-con-loading__container table-div-container"
          >
            <vs-table
              class="test-case-steps-table"
              :search="true"
              stripe
              :data="steps"
              :max-items="15"
              ref="table"
              :no-data-text="lang.general.noDataText[languageSelected]"
            >
              <!-- pagination
            @change-page="handleChangePage"
            :total="total"-->
              <template slot="header">
                <vs-button
                  id="btn-add-step"
                  class="float-left mb-3"
                  @click="addStep()"
                >
                  {{ lang.botMaker.testGroups.view.addStep[languageSelected] }}
                </vs-button>
              </template>
              <template slot="thead">
                <vs-th key="testCase">{{
                  lang.botMaker.testCases.table.step[languageSelected]
                }}</vs-th>
                <vs-th key="userSays">{{
                  lang.botMaker.testCases.table.userSays[languageSelected]
                }}</vs-th>
                <vs-th key="botResponse">{{
                  lang.botMaker.testCases.table.botResponse[languageSelected]
                }}</vs-th>
                <vs-th key="assertion">{{
                  lang.botMaker.testCases.table.assertion[languageSelected]
                }}</vs-th>
                <vs-th key="options"></vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr
                  :data="indextr"
                  :key="indextr"
                  v-for="(tr, indextr) in data"
                  class="bg-white"
                  @mouseover="() => undefined"
                  @mouseup="() => undefined"
                  height="60px"
                >
                  <vs-td>
                    <vs-row>
                      <vs-col vs-w="2">
                        {{ indextr + 1 }}
                      </vs-col>
                      <vs-col vs-w="10" class="enabled-container">
                        <vx-slider
                          :id="`disabled-option-${indextr}`"
                          v-model="data[indextr].enabled"
                          @change="change(indextr)"
                        />
                      </vs-col>
                    </vs-row>
                  </vs-td>
                  <vs-td :data="data[indextr].input">
                    <vs-input
                      v-model="data[indextr].input"
                      :danger="data[indextr].validations.input"
                      :id="`input-${indextr}`"
                      :danger-text="
                        lang.botMaker.testGroups.view.errors.text[
                          languageSelected
                        ]
                      "
                    />
                  </vs-td>
                  <vs-td :data="data[indextr].output[0]">
                    <vs-input
                      v-model="data[indextr].output[0]"
                      :danger="data[indextr].validations.output"
                      :id="`output-${indextr}`"
                      :danger-text="
                        lang.botMaker.testGroups.view.errors.text[
                          languageSelected
                        ]
                      "
                    />
                  </vs-td>
                  <vs-td>
                    <multiselect
                      class="assertion"
                      :id="`asserion-option-${indextr}`"
                      :allow-empty="false"
                      v-model="data[indextr].assertion"
                      :options="getAssertionTypes()"
                      :searchable="false"
                      :close-on-select="true"
                      :show-labels="false"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span>{{
                          lang.botMaker.testCases.assertionTypes[props.option][
                            languageSelected
                          ]
                        }}</span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <span>{{
                          lang.botMaker.testCases.assertionTypes[props.option][
                            languageSelected
                          ]
                        }}</span>
                      </template>
                    </multiselect>
                  </vs-td>
                  <vs-td>
                    <ul class="horizontal-nav">
                      <li
                        class="navbar-item cursor-pointer"
                        @click="deleteStep(indextr)"
                        :id="`delete-step-${indextr}`"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="inline-block mr-2"
                          svgClasses="w-6 h-6"
                        />
                      </li>
                    </ul>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
          <vs-button
            id="btn-save-steps"
            class="float-right mb-3"
            @click="saveSteps()"
            v-if="isAuthAux('konecta.test_groups.edit')"
          >
            {{ lang.botMaker.testGroups.view.saveSteps[languageSelected] }}
          </vs-button>
          <vs-button
            id="btn-cancel-steps"
            type="flat"
            class="float-right mr-4 mb-3"
            color="danger"
            @click="clearTestGroup"
          >
            {{ lang.botMaker.cancel[languageSelected] }}
          </vs-button>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
// import NewFormGroup from '../add-bot/Components/NewFormGroup.vue'
import staffManagementMixin from '@/mixins/staffManagementMixin'
import { TEST_CASE_ASSERTION_TYPES } from '../../../../constants/constants'

export default {
  name: 'botTest',
  mixins: [staffManagementMixin],
  components: {
    // NewFormGroup,
    KonectaDropdown: () => import('../../../../components/KonectaDropdown.vue'),
    Multiselect: () => import('vue-multiselect'),
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd')
    // VsTable: () => import('../../../../components/vx-table/vsTable'),
    // EmptyTable: () => import('../../components/EmptyTable.vue'),
  },
  props: {
    openAddTest: Function,
    openAddTestCase: Function,
    excecuteTestGroup: Function
  },
  data() {
    return {
      caseOptionsPopup: false,
      groupOptionsPopup: false,
      activePopup: false,
      createTestCase: {
        name: '',
        description: ''
      },
      validations: {
        createTestCase: {
          nameError: false,
          descriptionError: false
        }
      },
      steps: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    ...mapGetters('botMaker', [
      'selectedTestCase',
      'selectedTestGroup',
      'botId',
      'bot'
    ])
  },
  methods: {
    ...mapActions('botMaker', [
      'CREATE_TEST_CASE',
      'UPDATE_TEST_STEPS',
      'GET_TEST_GROUPS',
      'UNSET_TEST_GROUP',
      'UNSET_TEST_CASE',
      'GET_BOT'
    ]),
    openAddTestCasePopup() {
      this.activePopup = true
    },
    openTestGroupEditor(testGroup) {
      this.caseOptionsPopup = false
      this.groupOptionsPopup = false
      this.openAddTest(testGroup)
    },
    change(index) {
      this.steps[index].disabled = !this.steps[index].enabled
    },
    addStep() {
      this.steps.push({
        input: '',
        output: [''],
        assertion: 'equal',
        enabled: true,
        testGroupCase: this.selectedTestCase._id,
        disabled: false,
        validations: { input: false, output: false }
      })
    },
    cancel() {
      this.activePopup = false
    },
    clearTestGroup() {
      this.UNSET_TEST_CASE()
    },
    outsideCase: function () {
      this.caseOptionsPopup = false
      this.groupOptionsPopup = false
    },
    moreCaseOptions() {
      this.caseOptionsPopup = true
    },
    moreGroupOptions() {
      this.groupOptionsPopup = true
    },
    changeAssertion(value) {
      console.log('VALORES', value)
    },
    getAssertionTypes() {
      return TEST_CASE_ASSERTION_TYPES
    },
    deleteStep(index) {
      this.steps.splice(index, 1)
    },
    async saveSteps() {
      let error = false
      for (let i in this.steps) {
        if (this.steps[i].input === '' || !this.steps[i].input) {
          this.steps[i].validations.input = true
          error = true
        } else {
          this.steps[i].validations.input = false
        }
        if (
          !this.steps[i].output ||
          this.steps[i].output[0] === '' ||
          !this.steps[i].output[0]
        ) {
          this.steps[i].validations.output = true
          error = true
        } else {
          this.steps[i].validations.output = false
        }
      }
      if (error) {
        return
      }
      this.$vs.loading()
      try {
        await this.UPDATE_TEST_STEPS(this.steps)
        this.$vs.loading.close()
        this.$vs.notify({
          text: this.lang.botMaker.testGroups.view.messages.stepsUpdated[
            this.languageSelected
          ],
          color: 'success'
        })
      } catch (error) {
        this.$vs.notify({
          color: 'danger',
          text: this.lang.botMaker.error[this.languageSelected]
        })
        this.reloadSteps()
        this.$vs.loading.close()
      }
    },
    reloadSteps() {
      this.steps = this.selectedTestCase.testCaseSteps
      if (this.steps.length > 0) {
        this.steps = this.steps.map(s => ({
          ...s,
          enabled: s.disabled ? !s.disabled : true
        }))
      }
    }
  },
  async mounted() {
    this.steps = this.selectedTestCase.testCaseSteps
    if (this.steps.length > 0) {
      this.steps = this.steps.map(s => ({
        ...s,
        enabled: s.disabled ? !s.disabled : true,
        validations: { input: false, output: false }
      }))
    }
  }
}
</script>

<style lang="scss">
.test-case-detail {
  .test-case-steps-table {
    .multiselect {
      width: 220px;
      &__tags {
        width: unset;
      }
      &__single,
      &__option,
      &__input {
        font-size: 14px;
      }
    }
    background-color: #fafafa;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    .feather {
      width: 16px !important;
      height: 16px !important;
    }
  }

  .vs-table--content {
    overflow: visible !important;
  }
  .vs-con-tbody.vs-table--tbody {
    overflow: visible !important;
  }
  .table-div-container {
    overflow: visible !important;
  }
  #test-group {
    .title {
      margin-bottom: 20px;
    }
  }
  .back-button-container {
    display: inline-block;
  }
  .more-button-container {
    .option-row {
      align-items: center;
      margin: 3px !important;
    }
  }
  #btn-add-step {
    margin-left: 0px !important;
    margin-right: auto;
  }
  .back-button-icon {
    text-align: top;
    vertical-align: middle;
    margin-right: 0px !important;
  }
  .back-button-span {
    vertical-align: text-bottom;
  }
  .backButton {
    display: flex;
    align-items: center;
    cursor: pointer;

    &.active,
    // &:hover {
    //   color: rgba(var(--vs-primary), 1);
    // }

    .flag {
      margin-right: 5px;
      margin-bottom: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
  .moreCaseButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    &.active,
    // &:hover {
    //   color: rgba(var(--vs-primary), 1);
    // }

    .flag {
      margin-right: 5px;
      margin-bottom: 2px;
      border-radius: 50%;
      width: 16px;
      height: 16px;
    }
  }
}
</style>

<style scoped>
.center {
  margin: auto;
  width: 100%;
}
</style>

<style lang="scss">
.test-case-detail {
  .vs-table--tbody {
    z-index: 1 !important;
    min-width: 0% !important;
  }

  .vs-table--tbody-table {
    min-width: 0% !important;
  }
  .test-group-table {
    margin: 30px 0px;
    margin-top: 0px;
    padding: 20px;
    background-color: #fafafa;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .error-message {
      display: block;
      height: 19px;
      color: rgba(var(--vs-danger), 1);
      font-size: 0.65rem;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 30px;
    }
    .btn-actions {
      display: flex;
      justify-content: flex-end;
    }

    .vs-pagination--buttons,
    .item-pagination {
      width: 30px;
      height: 30px;
    }
    .item-pagination {
      font-size: 0.8rem;
    }

    .con-vs-pagination {
      padding: 7px;
    }
    .horizontal-nav {
      display: flex;
      float: right;
      margin-right: 0px;
      .navbar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 10px;

        .con-vs-tooltip {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .feather-icon {
            transition: all 0.3s;
            .feather {
              width: 20px !important;
              height: 20px !important;
            }

            &:hover,
            &.active {
              transform: scale(1.2);
              color: rgba(var(--vs-primary), 1) !important;
            }
          }

          .material-icons,
          .material-icons-outlined {
            font-size: 24px;
            transition: all 0.3s;
            &:hover,
            &.active {
              transform: scale(1.2);
              color: rgba(var(--vs-primary), 1) !important;
            }
          }
        }
      }
    }
  }
  .test-case-steps-table {
    .enabled-container {
      display: contents;
    }
    .multiselect {
      width: 300px;
      &--disabled {
        .multiselect__select {
          height: 42px;
        }
      }
      .multiselect__content-wrapper {
        font-size: 1rem;
        max-height: 160px !important;
        z-index: 300 !important;
      }
      .multiselect__input {
        font-size: 1rem;
      }
    }
  }
  .table-header {
    h2 {
      font-size: 14px;
    }
    .title {
      margin-bottom: 5px !important;
      h2 {
        color: grey;
        margin-bottom: 0px !important;
      }
    }
  }
  .container {
    overflow: visible;
  }

  .more-button-icon {
    float: right;
    margin-left: auto;
    margin-right: 0px !important;
  }
  .more-button-container {
    display: flex;
    position: relative;
    align-items: center;
    .more-button-icon-group {
      .konecta-dropdown {
        margin-top: 86px;
        right: -99px;
      }
    }
    .more-button-icon-case {
      .konecta-dropdown {
        margin-top: 66px;
        right: -82px;
      }
    }
  }
  .test-case-header {
    margin: 30px 0px;
    margin-top: 0px;
    padding: 20px;
    background-color: #fafafa;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    .error-message {
      display: block;
      height: 19px;
      color: rgba(var(--vs-danger), 1);
      font-size: 0.65rem;
    }
    h2 {
      font-size: 14px;
    }
    .title {
      margin-bottom: 5px !important;
      h2 {
        color: grey;
      }
    }
    .btn-actions {
      display: flex;
      justify-content: flex-end;
    }

    .vs-pagination--buttons,
    .item-pagination {
      width: 30px;
      height: 30px;
    }
    .item-pagination {
      font-size: 0.8rem;
    }

    .con-vs-pagination {
      padding: 7px;
    }
    .horizontal-nav {
      display: flex;
      float: right;
      margin-right: 0px;
      .navbar-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px 10px;

        .con-vs-tooltip {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .feather-icon {
            transition: all 0.3s;
            .feather {
              width: 20px !important;
              height: 20px !important;
            }

            &:hover,
            &.active {
              transform: scale(1.2);
              color: rgba(var(--vs-primary), 1) !important;
            }
          }

          .material-icons,
          .material-icons-outlined {
            font-size: 24px;
            transition: all 0.3s;
            &:hover,
            &.active {
              transform: scale(1.2);
              color: rgba(var(--vs-primary), 1) !important;
            }
          }
        }
      }
    }
  }
}
</style>